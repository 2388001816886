// Core
import React from 'react';
import { Navigate, Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { lazyImport } from '../utils/lazyImport';

import { ROUTE_URL, PUBLIC_URL, ROLE } from '../constant/routeUrl'
import DashboardScreen from '../pages/dashboard/dashboard';
import ViewEmployer from '../pages/employer/view';
import ViewSalesExecutive from '../pages/sales-executive/view.js';

// import RegistrationWizard from './registration-wizard';

import CreateEmployer from '../pages/employer/create';
import TargetCreate from '../pages/target/update.js';
import EmployerJobComplete from '../pages/disputes/employer-job-incomplete-list.js';
import EmployeeJobComplete from '../pages/disputes/employee-job-incomplete-list.js';
import JobPostViewPage from '../pages/jobs/JobPostViewPage.js';


// import { useLocation } from 'react-router-dom';
const { WebLayOut } = lazyImport(() => import('../components/Layout/index'), 'WebLayOut');
const { LoginScreen } = lazyImport(() => import('../pages/login/login.js'), 'LoginScreen');
const { EmployeeList } = lazyImport(() => import('../pages/employee/list'), 'EmployeeList');
const { EmployerList } = lazyImport(() => import('../pages/employer/list'), 'EmployerList');
const { EmployeeView } = lazyImport(() => import('../pages/employee/view'), 'EmployeeView');
const { RegistrationWizard } = lazyImport(() => import('../pages/employee/registration-wizard'), 'RegistrationWizard');
const { JobPostList } = lazyImport(() => import('../pages/payments/payment-pending-list'), 'JobPostList');
const { SalesExecutiveList } = lazyImport(() => import('../pages/sales-executive/list.js'), 'SalesExecutiveList');
const { PostJob } = lazyImport(() => import('../pages/jobs/create'), 'PostJob');
const { SalesExecutiveCreate } = lazyImport(() => import('../pages/sales-executive/create.js'), 'SalesExecutiveCreate');

const { JobPostListAll } = lazyImport(() => import('../pages/jobs/list'), 'JobPostListAll');
const { EmployerComplaintList } = lazyImport(() => import('../pages/complaints/employer-list'), 'EmployerComplaintList');
const { EmployeeComplaintView } = lazyImport(() => import('../pages/complaints/employee-view'), 'EmployeeComplaintView');

const { EmployeeComplaintList } = lazyImport(() => import('../pages/complaints/employee-list'), 'EmployeeComplaintList');
const { EmployerComplaintView } = lazyImport(() => import('../pages/complaints/employer-view'), 'EmployerComplaintView');
// start- sales manager 
const { SalesMangerCreate } = lazyImport(() => import('../pages/sales-manager/create.js'), 'SalesMangerCreate');
const { SalesMangerList } = lazyImport(() => import('../pages/sales-manager/list.js'), 'SalesMangerList');
// contact-profile-list
// const { SalesMangerCreate } = lazyImport(() => import('../pages/sales-manager/create.js'), 'SalesMangerCreate');
const { ContactProfileList } = lazyImport(() => import('../pages/contact-profile/list.js'), 'ContactProfileList');
const { FunnelSheetList } = lazyImport(() => import('../pages/funnel-sheet/list.js'), 'FunnelSheetList');
const { JobView } = lazyImport(() => import('../pages/disputes/job-view.js'), 'JobView');

export default function RouteComponent(props) {
    const token = localStorage.getItem("token");
    let currentLocation = useLocation().pathname;
    const loginType = JSON.parse(localStorage.getItem('currentUser'));
    const publicValue = PUBLIC_URL.find((element) => element === currentLocation)
    console.log("log1", currentLocation, "|", publicValue);

    if (currentLocation == '/') {
        if (token && loginType.types[0] === 3) {
            window.open(window.location.origin + '/dashboard', "_self");
        }
    }

    if ((currentLocation.includes('/dashboard') || currentLocation.includes('/job-post-list')|| currentLocation.includes('/employee-create')|| currentLocation.includes('/employee-list') || currentLocation.includes('/employer-list') || currentLocation.includes('/employer-view') || currentLocation.includes('/employee-view') || currentLocation.includes('/employee-complaint-view')
    || currentLocation.includes('/employer-complaint-list')|| currentLocation.includes('/employee-complaint-list')|| currentLocation.includes('/employer-complaint-view')) && !token && ROUTE_URL.indexOf(currentLocation) >= 0) {
        window.open(window.location.origin + '/', "_self")
    }

    return (
        <Routes>
            <Route path="/dashboard" element={<DashboardScreen pageTitle='dashboard' />} />
            <Route path="/" element={<LoginScreen pageTitle='login' />} />
            <Route path="/employee-list" element={<EmployeeList pageTitle='employee-list' />} />
            <Route path="/employer-list" element={<EmployerList pageTitle='employerlist' />} />
            <Route path="/employer-view" element={<ViewEmployer pageTitle='employerview' />} />
            <Route path="/employee-View" element={<EmployeeView pageTitle='employeeView' />} />
            {/* <Route path="/employee-create" element={<EmployeeCreate pageTitle='employee-create' />} */}
            <Route path="/employee-create" element={<RegistrationWizard pageTitle='employee-create' />}  />
            <Route path="/job-post-list" element={<JobPostList pageTitle='job-post-list' />}  />
            <Route path="/sales-executive-list" element={<SalesExecutiveList pageTitle='sales-executive-list' />}  />
            <Route path="/job-post-create" element={<PostJob pageTitle='job-post-create' />}  />
            <Route path="/job-post-view" element={<JobPostViewPage pageTitle='job-post-view' />}  />
            <Route path="/sales-executive-create" element={<SalesExecutiveCreate pageTitle='sales-executive-create' />}  />
            <Route path="/sales-executive-view" element={<ViewSalesExecutive pageTitle='salesexecutive' />} />
            <Route path="/job-post-list-all" element={<JobPostListAll pageTitle='job-post-list-all' />}  />
            <Route path="/employer-create" element={<CreateEmployer pageTitle='createEmployer' />} />
            <Route path="/target-update" element={<TargetCreate pageTitle='target-update' />} />
            <Route path="/employer-complaint-list" element={<EmployerComplaintList pageTitle='employerComplaintlist' />} />
            <Route path="/employee-complaint-list" element={<EmployeeComplaintList pageTitle='employeeComplaintlist' />} />
            <Route path="/employee-complaint-view" element={<EmployeeComplaintView pageTitle='EmployeeComplaintView' />} />
            <Route path="/employer-complaint-view" element={<EmployerComplaintView pageTitle='EmployerComplaintView' />} />
            <Route path="/manager-create" element={<SalesMangerCreate pageTitle='SalesMangerCreate' />} />
            <Route path="/manager-list" element={<SalesMangerList pageTitle='SalesMangerList' />} />
            <Route path="/contact-profile-list" element={<ContactProfileList pageTitle='ContactProfileList' />} />
            <Route path="/funnel-sheet-list" element={<FunnelSheetList pageTitle='FunnelSheetList' />} />

            <Route path="/employer-job-copmplete-list" element={<EmployerJobComplete pageTitle='EmployerJobComplete' />} />
            <Route path="/employee-job-copmplete-list" element={<EmployeeJobComplete pageTitle='EmployeeJobComplete' />} />
            <Route path="/job-complete-view" element={<JobView pageTitle='EmployeeJobView' />} />

        </Routes>
    )


}
