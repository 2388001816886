import React, { useEffect, useState, } from 'react';
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, Typography, Autocomplete, FormHelperText, FormControlLabel, RadioGroup, Radio, InputAdornment, IconButton, createFilterOptions } from '@mui/material';
// import { ReactComponent as PostJobBanner } from '../../../assets/images/post-job-banner.svg';
import EastIcon from '@mui/icons-material/East';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './employerStyle.scss'
import { PostJobCreate, JobCategoryTypes, JobQualification, JobCertification, JobSkillSet, createNewSkill, createNewQualification, createNewCertification, PostJobUpdate, EmployerListapi, PostJobView } from '../../sevices/account';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { ENV_CONFIG, VALIDATION_MSG } from '../../constant';
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import PageFooter from '../../components/Footer';
import Sidebar from '../../components/sidebar';
import PageHeader from '../../components/Header';
import Loader from '../../constant/Loader';
import { ReactComponent as UncheckIcon } from '../../assets/img/radio-uncheck.svg';
import { ReactComponent as CheckIcon } from '../../assets/img/radio-check.svg';
import { ReactComponent as CloseIcon } from '../../assets/img/CloseIcon2.svg';
import StateJson from '../../shared/snackBar/json/state.json';
import moment from 'moment';
import { isEmptyObj } from "../../utils/genericUtilities";
import CustomSnackBar from '../../shared/snackBar'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
const filterSkill = createFilterOptions();


const JobPostViewPage = (props) => {
    const minHours = 4; // Minimum hours
    const maxHours = 18; // Maximum hours
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [workingTimg, setWorkingTiming] = useState('');
    const [fromTiming, setFromTiming] = useState(null);
    const [toTiming, setToTiming] = useState(null);

    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [gender, setGender] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedToDate, setSelectedToDate] = useState('');
    const [category, setCategory] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyNameError, setCompanyNameError] = useState('');

    const [jobType, setJobType] = useState('');

    const [selectedcategory, setSelectedCategory] = useState('');

    const [jobSkills, setJobSkills] = useState([]);
    const [skillsValue, setSkillsValue] = useState([]);
    const [newSkillValues, setNewSkillValues] = useState('');

    const [qualification, setQualification] = useState([]);
    const [qualificationvalue, setQualificationValue] = useState([]);
    const [newQualificationValue, setNewQualificationValues] = useState([]);
    const [selectedQualification, setSelectedQualification] = useState([]);

    const [certification, setCertification] = useState([]);

    const [certificationvalue, setCertificationValue] = useState([]);
    const [selectedCertification, setSelectedCertification] = useState('');
    // const Filter = require('bad-words');
    // const badWordLists = new Filter({ regex: /\*|\.|$/gi });
    // const filter = new Filter();
    const [badWords, setBadWords] = useState([]);
    const [dressCode, setDressCode] = useState('')
    const [pickpDrop, setpickpDrop] = useState('')
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [customMsg, setCustomMsg] = useState({});

    const [isShortTerm, setIsShortTerm] = useState('false');
    const [isPublicOrPrivate, setIsPublicOrPrivate] = useState('Public');
    const [selectedGroup, setSelectedGroup] = useState(null)
    const [dressCodeYes, setDressCodeYes] = useState(false);
    const [memberName, setMemberName] = useState([]);
    const [fullName, setFullName] = useState('');
    const [memberID, setMemberID] = useState(null);
    const [brandName, setBrandName] = useState('');
    const [brandNameError, setBrandNameError] = useState('');
    const [createJobData, setCreateJobData] = useState({})
    const [errors, setErrors] = useState({})
    const [skillLists, setSkillLists] = useState([]);


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const viewid = searchParams.get('id');

    const currentDate = dayjs();

    const nextDay = dayjs(currentDate).add(1, 'day');
    const currentMoment = dayjs();
    const fourHoursFromNow = dayjs(currentMoment).add(4, 'hours');

    const handleSnackbarClick = (status, msgInfo) => {
        setOpenSnackbar(status);
        !isEmptyObj(msgInfo) && setCustomMsg(msgInfo);
    }


    // DATE FORMAT FUNCTION 
    const handleDateChange = (date) => {
        if (date) {
            delete errors?.working_date

            const inputDate = new Date(date);
            const formattedDate = `${inputDate.getFullYear()}-${(inputDate.getMonth() + 1).toString().padStart(2, '0')}-${inputDate.getDate().toString().padStart(2, '0')}`;
            const selectingDate = dayjs(formattedDate);
            setSelectedDate(selectingDate);
        } else {
            setErrors({ ...errors, working_date: 'Working date is required' })
        }


    };


    // CATEGORY SELECT FUNCTION 
    const handleSelectcategory = (selectedOption) => {
        const value = selectedOption?.name
        setSelectedCategory(selectedOption?.name)
        if (!value) {
            setErrors({ ...errors, category: 'Category is required' })
        } else {
            delete errors.category
        }


    };

    // TIME CHANGE FUNCTION 
    const handleTimeChange = (newValue) => {

        if (newValue) {
            delete errors.working_timing
            const formattedTime = newValue.format("hh:mm A");

            setWorkingTiming(formattedTime);

        }

    };

    // SET CERTIFICATION FUNCTION 
    const handleCertificationChange = (event) => {
        setSelectedCertification(event?.target?.value)
        if (event?.target?.value) {

        }

    };

    // ADD CERTIFICATION FUNCTION 
    const handleAddCertification = () => {
        if (selectedCertification) {


            setSelectedCertification('')
            const cleanedCertification = selectedCertification;

            if (cleanedCertification === selectedCertification) {

                if (selectedCertification && !certificationvalue.includes(selectedCertification)) {

                    setCertificationValue((prevData) => [...prevData, selectedCertification])

                }

            } else {
                setSelectedCertification('')
            }
        }
    }

    // REMOVE CERTIFICATION FUNCTION 
    const handleRemoveCertificate = (index, _certificate) => {

        const updatedCertificationValue = [...certificationvalue];
        updatedCertificationValue.splice(index, 1);
        setCertificationValue(updatedCertificationValue);
    }


    // QUALIFICATION  BADWORD REMOVE FUNCTION 
    const handleQualificationChange = (_event, value) => {
        delete errors?.required_qualifications
        const uniqueQualifications = value
            .map(Qualification => Qualification)
            .filter((Qualification, index, self) => self.indexOf(Qualification) === index);
        const containsBadWord = uniqueQualifications.findIndex(Qualification => {
            return badWords.some(badWord => Qualification.includes(badWord));
        });
        if (containsBadWord > -1) {
            uniqueQualifications.splice(containsBadWord, 1);
        }

        setQualificationValue(uniqueQualifications)
        setSelectedQualification(uniqueQualifications);
        const valuesNotInQualificationSet = uniqueQualifications.filter(Qualification => !qualification.map(s => s.name).includes(Qualification));
        setNewQualificationValues(valuesNotInQualificationSet);

    };


    // QUALIFICATION CHARACTER VALIDATION FUNCTION 
    const handleQualificationKeyDown = (event) => {
        delete errors?.required_qualifications
        const isValidChar = /^[a-zA-Z0-9\s]+$/.test(event.key);

        const isBackspace = event.key === 'Backspace';
        if ((event.target.value.length >= 50 && !isBackspace) || (!isValidChar && !isBackspace)) {
            event.preventDefault();
        }
    };

    // SELECT DRESS CODE FUNCTION 
    const handleSelectChange = (event) => {
        setDressCodeYes(event.target.value);
    };

    // ADDRESS VALIDATION FUNCTION 
    const handleAddress = async (event) => {
        const value = event.target.value;

        const sanitizedInput = value.replace(/[^0-9]/g, '').slice(0, 10);
        setZipcode(sanitizedInput);
        // const newErrors = {}

        if (value.length === 6) {
            delete errors.pincode
            try {

                const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(sanitizedInput)}&key=${ENV_CONFIG.GOOGLE_API_KEY}`);
                const results = response?.data?.results;

                if (results?.length > 0) {

                    const addressComponents = results[0]?.address_components;

                    let city = '';
                    let state = '';

                    addressComponents.forEach((component) => {
                        if (component.types.includes('locality')) {
                            city = component.long_name; // The city name
                        } else if (component.types.includes('administrative_area_level_3')) {
                            city = component.long_name;
                        }
                        if (component.types.includes('administrative_area_level_1')) {
                            state = component.long_name; // The state name
                        }
                    });

                    setCity(city)
                    setState(state)

                } else {
                    setErrors({ ...errors, pincode: 'Please enter valid pincode' })
                }
            } catch (error) {
                // continue regardless of error

            }
        } else if (!value) {
            setErrors({ ...errors, pincode: 'Pincode is required' })
        }


    }


    // STATE FUNCTION 
    const handleChangestate = (event) => {
        if (event.target.value) {
            setState(event.target.value);
            delete errors?.state
        }
    }

    // CITY FUNCTION 
    const handleChangeCity = (event) => {

        if (event.target.value) {
            setCity(event.target.value);
            delete errors?.city
        }
        // setCityError('')
    }

    // CATEGORY FUNCTION 
    const GetCategory = async (_event) => {
        const body = {
            status: 1
        }
        const response = await JobCategoryTypes(body);
        setCategory(response?.data?.items)
    };
    // CERTIFICATION LIST API FUNCTION 
    const GetCertificationList = async (_event) => {
        const body = {
            status: 1
        }
        const response = await JobCertification(body);
        setCertification(response?.data?.items)

    };
    // QUALIFICATIONLIST API FUNCTION 
    const GetQualificationList = async (_event) => {
        const body = {
            status: 1
        }
        const response = await JobQualification(body);
        setQualification(response?.data?.items)
    };
    // JOBSKILL API FUNCTION 
    const getJobSkill = async (_event) => {
        const body = {
            status: 1
        }
        const response = await JobSkillSet(body);

        const skills = response?.data?.items

        const skillNameList = skills?.map((skill) => skill?.name);
        setJobSkills(skillNameList);
        setSkillLists(skillNameList)
    };



    const getEmployerList = async () => {
        try {
            const response = await EmployerListapi(1, 400)
            if (response?.status === 200 && response?.data?.items) {
                setMemberName(response?.data?.items)
            }

        } catch (error) {
            console.log("erorrrrrr", error)

        }
    }

    const handleSelectNameChanges = (event, selectedValues) => {
        setFullName(selectedValues)
        if (selectedValues) {
            const selectedMember = memberName.find((data) => `${data?.first_name} ${data?.last_name}` === selectedValues);
            if (selectedMember) {
                const memberId = selectedMember.id;
                setMemberID(memberId)

            }
        }

    }

    // COMAPNY NAME CHNAGE VALIDATION FUNCTION 
    const handleCompanyNameChange = (event) => {
        const value = event.target.value
        if (!value) {
            setCompanyNameError(VALIDATION_MSG.companyNameRequired);
        } else {
            setCompanyNameError('');
        }
        setCompanyName(value.replace(/[^A-Za-z0-9 ]/ig, ''));
    }

    // TO DATE FUNCTION 
    const handleDateToChange = (date) => {

        const selectingToDate = dayjs(date, 'DD/MM/YYYY');
        setSelectedToDate(selectingToDate);

    };

    // FROM TIME CHNAGE FUNCTION 
    const handleFromTimeChange = (newValue) => {


        if (newValue) {
            const formattedTime = newValue.format("hh:mm A");
            setFromTiming(formattedTime);
            delete errors?.from_time

        }
    };
    // TO TIME CHANGE FUNCTION 
    const handleToTimeChange = (newValue) => {

        if (newValue) {
            const formattedTime = newValue.format("hh:mm A");
            setToTiming(formattedTime);
            delete errors.to_time

        }
    };


    const disableTime = (_current) => {
        if (!fromTiming) {
            return {};
        }

        const temp = moment(fromTiming);

        const startHour = temp.hour();
        const startMinute = temp.minute();


        // DISABLE HOURS FUNCTION 

        const disabledHours = () => {
            const hours = [];
            for (let i = 0; i < startHour; i++) {
                hours.push(i);
            }
            return hours;
        };
        // DISABLE MINUTE FUNCTION 
        const disabledMinutes = (selectedHour) => {
            if (selectedHour === startHour) {
                const minutes = [];
                for (let i = 0; i < startMinute; i++) {
                    minutes.push(i);
                }
                return minutes;
            }
            return [];
        };

        return {
            disabledHours,
            disabledMinutes,
        };
    };


    const handleHoursChange = (action) => {
        let currentHours = parseInt(createJobData?.job_timing_slot || minHours);

        if (action === 'increment' && currentHours < maxHours) {
            currentHours += 1;
        } else if (action === 'decrement' && currentHours > minHours) {
            currentHours -= 1;
        }
        setCreateJobData({ ...createJobData, job_timing_slot: `${currentHours}:00 hrs` })
    };
    const handleBrandChange = (event) => {
        const value = event.target.value
        if (!value) {
            setBrandNameError("Brand Name is required");
        } else {
            setBrandNameError('');
        }
        setBrandName(value.replace(/[^A-Za-z0-9 ]/ig, ''));
    }

    const handleCreateJobChange = (event) => {

        event.preventDefault()
        const newErrorsData = { ...errors }
        const { value, name } = event?.target;

        switch (name) {
            case 'title':
                if (!value) {
                    newErrorsData.title = 'Job title is required'
                } else {
                    delete newErrorsData?.title
                }
                break;
            case 'job_salary':
                if (!value) {
                    newErrorsData.job_salary = 'Job salary is required'
                } else if (value < 400) {
                    newErrorsData.job_salary = 'Minimum Job Salary must be Rs. 400'
                } else {
                    delete newErrorsData?.job_salary
                }
                break;
            case 'job_description':
                if (!value) {
                    newErrorsData.job_description = 'Description is required'
                } else {
                    delete newErrorsData?.job_description
                }
                break;
            case 'experience':
                if (!value) {
                    newErrorsData.experience = 'Experince is required'
                } else {
                    delete newErrorsData?.experience
                }
                break;

            default:
                break;
        }

        setErrors(newErrorsData)

        setCreateJobData((prevData) => {
            return { ...prevData, [name]: value }
        })

    }

    const handleSkillChange = (event, newValue) => {
        const badSkillData = newValue?.filter((skill) => {
            return badWords.some((bad) => skill === bad);
        })

        if (badSkillData?.length > 0) {
            const updateSkill = newValue.filter(skill => !badSkillData?.some(bad => JSON.stringify(skill) === JSON.stringify(bad)));
            setSkillsValue(updateSkill);
        } else if (badSkillData?.length === 0) {
            setSkillsValue(newValue);
        }

        const newSkillData = newValue?.filter((skill) => {
            if (!skillLists?.includes(skill)) {
                return skill
            }
        })


        if (newSkillData?.length > 0) {
            setNewSkillValues(newSkillData?.map(skill => skill))
        }


    }


    const HandleViewjob = async () => {
        setLoading(true);
        const param = { id: viewid }
        try {

            const response = await PostJobView({ params: param });
            const ViewData = response?.data?.details
            setFullName(ViewData?.employer_profile?.first_name)
            const skilValue = ViewData?.skills

            const skillArray = skilValue.split(',');
            setSkillsValue(skillArray)
            setCreateJobData(ViewData)
            setCompanyName(ViewData?.company_name)
            setBrandName(ViewData?.employer_profile?.brand_name)
            setQualificationValue(ViewData?.required_qualifications)
            setSelectedDate(ViewData?.working_date)
            setSelectedToDate(ViewData?.short_term_end_date)
            setCertificationValue(ViewData?.certification_required)
            const defaultTime = dayjs(ViewData.working_timing, 'HH:mm');
            setWorkingTiming(defaultTime)
            setCity(ViewData.city)
            setState(ViewData.state)
            setZipcode(ViewData.zip_code)
            setGender(ViewData.gender)
            setDressCode(ViewData?.dress_code);
            setDressCodeYes(ViewData?.dress_code ? "Yes" : "No")
            setpickpDrop(ViewData?.pickup_drop)


            setSelectedCategory(ViewData?.category_type);
        } catch (error) {
            // continue regardless of error

        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (viewid) {
            HandleViewjob();
        }


        GetCategory();
        GetCertificationList();
        GetQualificationList();
        getJobSkill();
        getEmployerList()

        // const badWordList = badWordLists?.list
        // setBadWords(badWordList)
    }, [viewid]);


    return (
        <div className="d-flex flex-column flex-root header-fixed">
            <div className="page d-flex flex-row flex-column-fluid">
                <Sidebar />
                <div className="wrapper d-flex flex-column flex-row-fluid w-100 page-layout">
                    <PageHeader />
                    <div className="dashboardPageContainer">
                        <div className="mainTitle">Post Job</div>
                        <div className="fullWidth">
                            <Grid container spacing={5}>
                                <Grid item xs={12} sm={8}>
                                    <div className="WizardFormContainer">
                                        <form>
                                            <Grid container spacing={5}>
                                              
                                                {isShortTerm === "false" && <Grid item xs={12} sm={12} md={6}>
                                                    <label htmlFor="pickup-drop-radio-group" style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '400', color: '#605757' }} >Post type</label>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="fresher"
                                                        name="radio-buttons-group"
                                                        className="picukpradio"
                                                        sx={{ display: 'flex', flexDirection: 'row', marginLeft: '10px', marginTop: '10px' }}
                                                        value={isPublicOrPrivate}
                                                        onChange={(e) => {
                                                            if (e.target.value === "Public") {
                                                                setIsPublicOrPrivate(e.target.value)
                                                            } else if (e.target.value === "Private") {
                                                                setIsPublicOrPrivate(e.target.value)
                                                            }
                                                        }}
                                                    >
                                                        <FormControlLabel value='Public' control={<Radio icon={<UncheckIcon />}
                                                            checkedIcon={<CheckIcon />} />} label="Public" />
                                                        {/* <FormControlLabel value='Private' control={<Radio icon={<UncheckIcon />}
                                                            checkedIcon={<CheckIcon />} />} label="Private" /> */}
                                                    </RadioGroup>
                                                </Grid>}
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Employer"
                                                        value={fullName || ''}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Job title"
                                                        value={createJobData?.title || ''}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Company name"
                                                        value={companyName || ''}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="brand name"
                                                        value={brandName || '-'}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid>

                                                {(isShortTerm === 'false') ?
                                                    <Grid item xs={12} sm={6}>
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                            {/* Label */}
                                                            <TextField
                                                                label="Job Hours "
                                                                value={createJobData?.job_timing_slot || '4:00 hrs'}
                                                                InputProps={{ readOnly: true }}
                                                            />
                                                        </div>
                                                    </Grid>
                                                    : null}
                                                <Grid item xs={12} sm={6}>

                                                    <TextField
                                                        label="Working From Date"
                                                        value={selectedDate ? dayjs(selectedDate).format('DD/MM/YYYY') : 'DD/MM/YYYY'}  // Format date to DD/MM/YYYY
                                                        InputProps={{
                                                            readOnly: true,  // Makes it non-editable
                                                        }}
                                                        variant="outlined"  // Same border style as other fields
                                                        fullWidth  // Ensures it occupies the full width
                                                    />
                                                </Grid>

                                                {(isShortTerm === 'true') && <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Working To Date"
                                                        value={selectedToDate ? dayjs(selectedToDate).format('DD/MM/YYYY') : 'DD/MM/YYYY'}  // Format date to DD/MM/YYYY
                                                        InputProps={{
                                                            readOnly: true,  // Makes it non-editable
                                                        }}
                                                        variant="outlined"  // Same border style as other fields
                                                        fullWidth  // Ensures it occupies the full width
                                                    />

                                                </Grid>}

                                                {(isShortTerm === 'false') && <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Select Start Time"
                                                        value={workingTimg ? dayjs(workingTimg).format('hh:mm A') : ''}  // Format timestamp to time (HH:mm) // Display the selected working time
                                                        InputProps={{
                                                            readOnly: true,  // Make it non-editable
                                                        }}
                                                        variant="outlined"  // Same border style as other fields
                                                        fullWidth  // Ensures it occupies the full width
                                                    />

                                                </Grid>}

                                                <Grid item xs={12} sm={6}>
                                                    <Grid item xs={12} sm={12}>

                                                        <TextField
                                                            label="Category"
                                                            value={category && category.find((option) => option?.name === selectedcategory)?.name || ''}
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            variant="outlined" // Keeps the same border style as in your example
                                                            fullWidth // Makes the input field occupy the full width
                                                        />

                                                    </Grid>
                                                </Grid>

                                                {isShortTerm === 'false' ? <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Job salary"
                                                        value={createJobData?.job_salary || '-'}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid> : null}
                                                {isShortTerm === 'true' ?
                                                    <> <Grid item xs={12} sm={6}>

                                                        <TextField
                                                            label="Start Time"
                                                            value={fromTiming ? dayjs(fromTiming).format('HH:mm a') : 'HH:MM'} // Format timestamp to time (HH:mm)
                                                            InputProps={{
                                                                readOnly: true,  // Makes it non-editable
                                                            }}
                                                            variant="outlined"  // Same border style as other fields
                                                            fullWidth  // Ensures it occupies the full width
                                                        />

                                                    </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                label="Start Time"
                                                                value={toTiming ? dayjs(toTiming).format('HH:mm a') : 'HH:MM'} // Format timestamp to time (HH:mm)
                                                                InputProps={{
                                                                    readOnly: true,  // Makes it non-editable
                                                                }}
                                                                variant="outlined"  // Same border style as other fields
                                                                fullWidth  // Ensures it occupies the full width
                                                            />
                                                        </Grid></>
                                                    : null}

                                                <Grid item xs={12} sm={6} >
                                                    <label>Required Degree/Certifications</label> {/* Label */}
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            gap: '10px',
                                                            marginTop: '10px',
                                                        }}
                                                    >
                                                        {certificationvalue?.length > 0 &&
                                                            certificationvalue?.map((certifi, i) => (
                                                                <div
                                                                    key={i}
                                                                    style={{
                                                                        backgroundColor: '#d3d3d3', // Grey background for tags
                                                                        padding: '4px 12px',
                                                                        borderRadius: '20px', // Rounded corners
                                                                        fontSize: '0.875rem',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        color: '#333',
                                                                    }}
                                                                >
                                                                    {certifi}
                                                                </div>
                                                            ))}
                                                    </div>



                                                </Grid>
                                                {isShortTerm === 'true' ?
                                                    <Grid item xs={12} sm={12}>

                                                        <FormControl fullWidth>
                                                            <TextField
                                                                label="Type of Job"
                                                                value={jobType || '-'}  // Display the selected job type
                                                                InputProps={{
                                                                    readOnly: true,  // Makes it non-editable
                                                                }}
                                                                variant="outlined"  // Keeps the same border style as other fields
                                                            />
                                                        </FormControl>
                                                    </Grid> : null}


                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        className='post-job-textarea'
                                                        label="Job description"
                                                        multiline
                                                        rows={8}
                                                        value={createJobData?.job_description || ""}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <div style={{ paddingBottom: '10px' }}>
                                                        <label>Required Qualification</label> {/* Label */}
                                                    </div>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                                        {qualificationvalue?.map((option, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    padding: '4px 12px',
                                                                    backgroundColor: '#d3d3d3', // Grey background
                                                                    borderRadius: '20px', // Rounded corners
                                                                    fontSize: '0.875rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    color: '#333',
                                                                }}
                                                            >
                                                                {option}
                                                            </div>
                                                        ))}
                                                    </div>

                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            value={createJobData?.experience || ''}
                                                            label="Experience"
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={12}>
                                                    <label>Preferred Skills</label> {/* Label */}
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                                        {skillsValue?.map((skill, index) => (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    padding: '4px 12px',
                                                                    backgroundColor: '#d3d3d3', // Grey background
                                                                    borderRadius: '20px', // Rounded corners
                                                                    fontSize: '0.875rem',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    color: '#333',
                                                                }}
                                                            >
                                                                {skill}
                                                            </div>
                                                        ))}
                                                    </div>

                                                </Grid>
                                                {isShortTerm === 'false' ? <Grid item xs={12} sm={12}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            label="Dress code"
                                                            value={dressCodeYes}
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </FormControl>
                                                </Grid> : null}
                                                {dressCodeYes === "Yes" && isShortTerm === 'false' ? (
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            label=" About Dress Code"
                                                            value={dressCode ? `${dressCode[0].toUpperCase()}${dressCode.slice(1).toLowerCase()}` : ''}
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </Grid>) : null}
                                                {isShortTerm === 'false' ? <Grid item xs={12} sm={12}>
                                                    <label htmlFor="pickup-drop-radio-group" style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '400', color: '#605757' }} >Pickup/Drop</label>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="fresher"
                                                        name="radio-buttons-group"
                                                        className="picukpradio"
                                                        sx={{ display: 'flex', flexDirection: 'row', marginLeft: '10px', marginTop: '10px' }}
                                                        value={pickpDrop}
                                                    >
                                                        <FormControlLabel
                                                            value="true"
                                                            control={<Radio icon={<UncheckIcon />} checkedIcon={<CheckIcon />} disabled />}
                                                            label="Yes"
                                                        />
                                                        <FormControlLabel
                                                            value="false"
                                                            control={<Radio icon={<UncheckIcon />} checkedIcon={<CheckIcon />} disabled />}
                                                            label="No"
                                                        />
                                                    </RadioGroup>

                                                </Grid> : null}

                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        id="address"
                                                        label="Job Location"
                                                        value={createJobData?.address || ''}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                    <FormHelperText error>{errors?.address}</FormHelperText>
                                                </Grid>
                                                <Grid item xs={12} sm={12}>
                                                    <TextField
                                                        label="Area"
                                                        value={createJobData?.area || ''}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <TextField
                                                        label="Pin code "
                                                        value={zipcode || ''}
                                                        InputProps={{ readOnly: true }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            label="State "
                                                            value={state || ''}
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </FormControl>

                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            label="City"
                                                            value={city || ''}
                                                            InputProps={{ readOnly: true }}
                                                        >
                                                        </TextField>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={12} sm={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            label="Gender"
                                                            className="form-control"
                                                            value={gender ? `${gender[0].toUpperCase()}${gender.slice(1).toLowerCase()}` : ''}
                                                            InputProps={{ readOnly: true }}
                                                        />
                                                    </FormControl>

                                                </Grid>

                                            </Grid>

                                        </form>
                                    </div>
                                </Grid>

                            </Grid>
                        </div>
                    </div>
                    <PageFooter />
                </div>
            </div>
            <Loader loading={loading} />
            {openSnackbar ? <CustomSnackBar
                openSnackbar={openSnackbar}
                snackBarMessage={customMsg?.msg}
                status={customMsg?.type}
                handleSnackbarClick={handleSnackbarClick}
            /> : null}
        </div >
    );
}

export default JobPostViewPage;